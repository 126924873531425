/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import type { NextComponentType } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useUserContext } from '../hooks';
import { getClientSideCookie } from '../utils';

export const withAuth = (WrappedComponent: NextComponentType) => {
  const Wrapper = (props: Record<string, unknown>) => {
    const router = useRouter();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const { user, authnCookieName } = useUserContext();

    useEffect(() => {
      const { pathname } = window.location;
      const userToken = getClientSideCookie(authnCookieName);
      if (!userToken && !pathname.startsWith('/login')) {
        router.replace('/login');
      }

      if (userToken && pathname.startsWith('/login')) {
        router.replace('/');
      }

      setIsVisible(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- New router instance causes re-render, thus spamming console with throttles
    }, [user, router]);

    if (!isVisible) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};
