/* eslint-disable @typescript-eslint/no-misused-promises */
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useUserContext } from '../../../hooks';
import { Error, LoadingButton, TextField } from '../../generic';

import type { Login } from './LoginType';
import { PasswordField } from './PasswordField';

const LoginFieldBox = styled(Box)({
  paddingBottom: '36px',
  width: '100%',
});

export const LoginForm = () => {
  const { refreshUserData, setErrorString } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<Login>({
    reValidateMode: 'onSubmit',
  });

  const showError = () => setIsError(true);
  const hideError = () => setIsError(false);

  const loginSubmit = async ({ login, password }: Login) => {
    try {
      setErrorString('');
      setIsLoading(true);
      const response = await fetch('/api/authenticate', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Access-Control-Allow-Credentials': 'true',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ login, password }),
      });
      setIsLoading(false);
      refreshUserData();

      const responseString = response.url.split('?');
      if (responseString[1] === 'error=invalid_username_or_password') {
        setErrorString(responseString[1]);
        setError('login', {
          type: 'custom',
          message: 'Incorrect login or password.',
        });
        setError('password', { type: 'custom' });
        showError();
      } else {
        window.location.href = '/?logged=true';
      }
    } catch (error: unknown) {
      setIsLoading(false);
      showError();

      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(loginSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <LoginFieldBox>
            <TextField
              autoComplete="on"
              error={!!errors.login}
              fullWidth
              placeholder="Enter your email"
              type="email"
              {...register('login', {
                required: true,
              })}
            />
          </LoginFieldBox>
          <LoginFieldBox>
            <PasswordField error={!!errors.password} register={register} />
          </LoginFieldBox>
          <LoadingButton loading={isLoading} type="submit">
            Login
          </LoadingButton>
        </Box>
      </form>
      {isError && (
        <Error buttonText="Close" onClickCallback={hideError} open>
          <p>{errors.login?.message ?? 'Sorry, an error occured.'}</p>
        </Error>
      )}
    </>
  );
};
