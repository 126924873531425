import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { NextPage } from 'next';
import Head from 'next/head';

import { IntroContainer, Paper } from '../components/generic';
import { LoginForm } from '../components/layout/login';
import { withAuth } from '../with';

const StyledH1 = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,

  '@media': {
    fontSize: '1.3rem',
    lineHeight: '27px',
    fontWeight: '400',
    textAlign: 'left',
    paddingBottom: '33px',
  },
}));

const LoginPaper = styled(Paper)(({ theme }) => ({
  padding: '20px 32px 40px 36px',
  backgroundColor: theme.palette.backgroundDistant.main,
  width: '418px',
}));

const Login: NextPage = () => (
  <>
    <Head>
      <title>ACO | Login</title>
    </Head>
    <IntroContainer>
      <LoginPaper>
        <StyledH1 variant="h1">USER LOGIN</StyledH1>
        <LoginForm />
      </LoginPaper>
    </IntroContainer>
  </>
);

export default withAuth(Login);
