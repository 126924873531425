import {
  Visibility as Eye,
  VisibilityOff as EyeOff,
} from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';
import { useState } from 'react';
import type { ChangeEvent } from 'react';
import type { UseFormRegister } from 'react-hook-form';

import { TextField } from '../../generic';

import type { Login } from './LoginType';

type PasswordFieldProps = {
  error: boolean;
  register: UseFormRegister<Login>;
};

export const PasswordField = ({ error, register }: PasswordFieldProps) => {
  const [isPassword, setIsPassword] = useState(true);
  const [isEyeDisplayed, setIsEyeDisplayed] = useState(false);

  const displayEyeOnInput = (event: ChangeEvent<HTMLInputElement>) =>
    event.target.value.length === 0
      ? setIsEyeDisplayed(false)
      : setIsEyeDisplayed(true);

  const showPassword = () =>
    setIsPassword((previousIsPassword) => !previousIsPassword);

  return (
    <TextField
      autoComplete="on"
      endAdornment
      error={error}
      fullWidth
      onInput={displayEyeOnInput}
      placeholder="Enter your password"
      type={isPassword ? 'password' : 'text'}
      InputProps={
        isEyeDisplayed
          ? {
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    marginLeft: '0px',
                    paddingRight: '28px',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={showPassword}
                  >
                    {isPassword ? <Eye /> : <EyeOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }
          : {}
      }
      {...register('password', {
        required: true,
      })}
    />
  );
};
